$radio-button-white: #f4f4f4;
$radio-button-blue: #3197EE;

.button {
    padding: 5px 15px;
    border-radius: 5px;
    margin: 2px 3px;
    cursor: pointer;
    text-align: center;
}

.button-no-margin {
    padding: 5px 15px;
    margin: 0px;
    cursor: pointer;
    text-align: center;
    max-height: 30px;
}

.button-small {
    font-size: 12px;
    padding: 2px 10px;
    border-radius: 5px;
    margin: 2px 3px;
    cursor: pointer;
    text-align: center;
}

.button-dropdown-small {
    /* padding: 2px 2px; */
    font-size: 22px;
    border-radius: 5px;
    margin: 2px 3px;
    cursor: pointer;
    text-align: center;
}

.icon-button {
    padding: 2px 15px;
    border-radius: 5px;
    margin: 2px 3px;
    cursor: pointer;
}

.button-gray {
    background-color: #D8D8D8;
    color: #ffffff;
}

.button-blue {
    background-color: #3B99FD;
    color: #ffffff;
}

.button-red {
    background-color: red;
    color: #ffffff;
}

.button-white {
    background-color: #ffffff;
    color: #000000;
    border: 1px solid #d6d6d6;
}

.button-black {
    background-color: #000000;
    color: #ffffff;
    border: 1px solid #d6d6d6;
}

.active-button {
    background-color: #ffffff;
    color: #000000;
    border-left: 1px solid #d6d6d6;
    border-right: 0.5px solid #d6d6d6;
    border-top: 1px solid #d6d6d6;
    border-bottom: 1px solid #d6d6d6;
    max-height: 30px;
}

.active-button-blue {
    background-color: #3B99FD;
    color: #ffffff;
    border-left: 1px solid #d6d6d6;
    border-right: 0.5px solid #d6d6d6;
    border-top: 1px solid #d6d6d6;
    border-bottom: 1px solid #d6d6d6;
    max-height: 30px;
}

.inactive-button {
    background-color: #ffffff;
    color: #000000;
    border-left: 0.5px solid #d6d6d6;
    border-right: 1px solid #d6d6d6;
    border-top: 1px solid #d6d6d6;
    border-bottom: 1px solid #d6d6d6;
    max-height: 30px;
}

.inactive-button-blue {
    background-color: #3B99FD;
    color: #ffffff;
    border-left: 0.5px solid #d6d6d6;
    border-right: 1px solid #d6d6d6;
    border-top: 1px solid #d6d6d6;
    border-bottom: 1px solid #d6d6d6;
    max-height: 30px;
}

.button-white:hover {
    background-color: #F2F2F2;
}

.button-white.selected {
    background-color: #e0e0e0;
}

.button-orange {
    background-color: #ee4823;
    color: #ffffff;
}

.button-red {
    border: 1px solid #f92f2f;
    background-color: #ff7272;
    color: #ffffff;
}

.hollow {
    background-color: #fff;
    color: #000;
}

.button-disabled {
    opacity: .5;
    cursor: default;
}

.button-white:hover {
    background-color: #ffffff;
}

.radio {
    margin: 0.5rem;
    input[type="radio"] {
      position: absolute;
      opacity: 0;
      + .radio-label {
        &:before {
          content: '';
          background: $radio-button-white;
          border-radius: 100%;
          border: 1px solid darken($radio-button-white, 25%);
          display: inline-block;
          width: 1.4em;
          height: 1.4em;
          position: relative;
          top: -0.2em;
          margin-right: 1em; 
          vertical-align: top;
          cursor: pointer;
          text-align: center;
          transition: all 250ms ease;
        }
      }
      &:checked {
        + .radio-label {
          &:before {
            background-color: $radio-button-blue;
            box-shadow: inset 0 0 0 4px $radio-button-white;
          }
        }
      }
      &:focus {
        + .radio-label {
          &:before {
            outline: none;
            border-color: $radio-button-blue;
          }
        }
      }
      &:disabled {
        + .radio-label {
          &:before {
            box-shadow: inset 0 0 0 4px $radio-button-white;
            cursor: default;
          }
        }
      }
      + .radio-label {
        &:empty {
          &:before {
            margin-right: 0;
          }
        }
      }
    }
  }