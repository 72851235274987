.descriptor-chip-main {
    background-color: #1a6fd8;
    color: white;
    padding: 2px 8px;
    margin: auto 4px;
    border-radius: 10px;
    font-size: 12px;
}

.descriptor-chip-dark {
    background-color: #424242;
    color: white;
    padding: 2px 8px;
    margin: auto 4px;
    border-radius: 10px;
    font-size: 12px;
    text-align: center;
}