@import '../material/style-vars.scss';

mat-button-toggle-group.mat-button-toggle-group.toggle-smaller {
  height: 36px;
  .mat-button-toggle {
    height: 36px;
    .mat-button-toggle-button {
      height: 36px;
      .mat-button-toggle-label-content {
        height: 36px;
        line-height: 36px;
        padding: 0 8px;
      }
    }
  }
}

mat-button-toggle-group.mat-button-toggle-group.toggle-slim {
  height: 30px;
  border: none;
  border-radius: 4px;
  .mat-button-toggle {
    height: 30px;
    border: none;
    border-radius: 4px;
    .mat-button-toggle-button {
      height: 30px;
      .mat-button-toggle-label-content {
        height: 30px;
        line-height: 34px;
        padding: 0 6px;
        .mat-icon {
          font-size: 1.25rem;
          &.mat-icon-no-color {
            color: $grey-med-dark;
          }
        }
      }
    }
    &.mat-button-toggle-disabled {
      .mat-button-toggle-button {
        .mat-button-toggle-label-content {
          mat-icon.mat-icon-no-color {
            color: $grey-med-light;
          }
          background-color: #f5f5f5;
        }
      }
    }
  }
}
