@import './app/view/material/app-theme.scss';
@import './app/view/material/style-vars.scss';
@import './app/view/styles/index.scss';
@import './app/view/styles/toggle-slim.scss';
@import '../node_modules/angular-calendar/scss/angular-calendar.scss';

html,
body {
  height: 100%;
}

.cdk-overlay-connected-position-bounding-box {
  z-index: 99999 !important;
}

body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.input-icon {
  position: relative;
  top: 6px;
  color: $grey-med-dark;
}

.error-text {
  color: #f44336 !important;
}

.timepicker {
  .timepicker__header {
    background-color: $color-primary !important;
  }

  .clock-face__number {
    & > span.active {
      background-color: $color-primary !important;
    }
  }

  .clock-face__clock-hand {
    background-color: $color-primary !important;
  }

  .timepicker-button {
    color: $color-primary !important;
  }
}

.error-snackbar {
  background-color: $color-warn !important;
  color: white;

  .mat-simple-snackbar-action {
    color: white;
  }
}

.normal-snackbar {
  .mat-simple-snackbar-action {
    color: $color-primary;
  }
}

.table-chip {
  max-width: 200px;
  white-space: nowrap;
}

.label-row {
  background-color: white;
  border-bottom: 1px #555;
  margin-top: 7px;
  padding: 5px 5px 5px 10px;
  border-radius: 6px;
  min-height: 32px;
  line-height: 32px;
  cursor: pointer;
}

.mat-sort-header-container {
  button.mat-sort-header-button {
    text-align: left;
  }
}

.right-align {
  text-align: right;
  .mat-sort-header-container {
    button.mat-sort-header-button {
      text-align: right;
    }
  }
}

.clickable-row {
  cursor: pointer;
}
