@use '@angular/material' as mat;

$topbar-height: 60px;

$color-primary: mat.get-color-from-palette(mat.$blue-palette, 400);
$color-accent: mat.get-color-from-palette(mat.$deep-orange-palette, 400);
$color-warn: mat.get-color-from-palette(mat.$red-palette, 500);

$grey-light: #eeeeef;
$grey-med-light: #c7c7c7;
$grey-medium: #999999;
$grey-med-dark: #555555;
$grey-dark: #333333;

$black-text: rgba(black, 0.87);
