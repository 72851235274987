@import '../shared/styles/popups.scss';

.toolbar-container {
  // background-color: #EEEEEE;
  height: 60px;
  color: #999999;

  a {
    color: #999999;
    text-decoration: none;
  }
}

.toolbar-container-expanded {
  // width: calc(100% - 70px);
  // background-color: #EEEEEE;
  height: 60px;
  color: #999999;
}

.tool-user-options {
  padding-right: 10px;
}

.tab-link {
  height: 100%;
  margin: 0 10px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
}

.tab-link.active {
  border-bottom: 2px solid #3b99fd;
}

.tab-link.active a {
  color: #3b99fd;
}

.search-bar {
  border: 1px solid #c7c7c7;
  border-radius: 5px;
  margin: auto 10px;
  padding: 5px;
  background-color: #fff;
  right: 20px;
}

.search-bar-mileage {
  border: 1px solid #c7c7c7;
  border-radius: 5px;
  margin: auto 0px;
  padding: 5px;
  background-color: #fff;
  right: 20px;
}

.search-bar input {
  border: none;
  outline: none;
  background-color: inherit;
  width: 100%;
}

.icon-large {
  font-size: 1.2em;
}

.detail-pane-back {
  cursor: pointer;
  color: #999999;
  padding: 10px;
}

.detail-pane-background {
  cursor: pointer;
  color: #999999;
  padding: 2px;
}

.list-options {
  padding: 3px;
}

.list-options:hover {
  background-color: #ebebeb;
  cursor: pointer;
  border-radius: 4px;
}
