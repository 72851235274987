@import "./mixins";
@import "./variables";

// @for $i from 1 through 100 {
//     .padding-#{$i} {
//         padding-left: #{$i}px;
//     }
// }

.popup-title {
    @include popup-title-template;
}

.popup-h2 {
    font-size: 1.6em;    
}

.popup-h3 {
    font-size: 1.4em;
}

.popup-expanded-row {
    background-color: #EBEBEB;
    padding: 5px 5px;
    cursor: pointer;
    border-radius: 4px;
}